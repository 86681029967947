import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{` Similar Portfolios `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-monthly/"
            }}>{`All-Stars Monthly`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-tr/"
            }}>{`All-Stars Total Return`}</a></li>
        </ul>
      </Aside>
      <ul>
        <li parentName="ul">{`Objective: balanced growth`}</li>
        <li parentName="ul">{`Type: meta-portfolio`}</li>
        <li parentName="ul">{`Invests in: ETFs tracking stocks, bonds, commodities, and hard assets`}</li>
        <li parentName="ul">{`Rebalancing schedule: monthly`}</li>
        <li parentName="ul">{`Taxation: 70% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $25,000`}</li>
      </ul>
      <p><em parentName="p">{`Back to Basics`}</em>{` is a meta-strategy combining three momentum strategies from well-regarded online publications. We introduced the strategy in October 2020.`}</p>
      <p><em parentName="p">{`Back to Basics`}</em>{` aims to deliver improved risk/ return characteristics by diversifying across multiple sub-styles of momentum strategies. In addition to simple momentum, these styles include growth-trend timing, walk-forward optimization, and risk-management via a Canary Universe.`}</p>
      <p>{`With its monthly rebalancing schedule, `}<em parentName="p">{`Back to Basics`}</em>{` has low maintenance requirements.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAE3klEQVQ4y2VUWWyUVRS+0iclvhgSeSAQjFAopWWVJeDGYgqiDxIMZakiRkz0wQVZhAiVLcGWgAoFElpppzNTpmWGLpS2086/3HPvv8zSzkwLEqG+woviE/2n95jzUwyJD+ffcu73f+d83zlMSsEcR7J79zKMc14kBDBNM5kQsBQAjgJAFAAMANABIAIA+6WEEsPglMMAoIjyEZFZlmA+WDptM9Pkkzj3k8oBoBsAkIJzjob5vygYJg/ejJszXFswW0LR72nB6upijI2ODj4L9jEAjBMIACjOuScEeBlXFCbCmwikGErKv1KOqEjagrk2FOm6yfwynwHDCbAxzrkSAlQ8Yarz4X51IdyvfgnF1c/BOJ5rjqufGvvGapv6sC9hYsoR69Ku8MunCwMB5ZzzcZNzNDkv0F03uEo7Qp1q6EX25m/I1jYi29CEbGNATXq/Wb28LYxLvmzzot06WoI/7B0wpgIQQ8NkAqDbloCOBWOO5d8VvQ8lJbZ0arh2XxQ/r+3CVz+5huv2RTGeMLF3wPRzpIAx27ZRCFEnpWTMkrCUGLX36Cp6S1fRbl1d79aR/hzp0rD1poaWAPxz2MZFX7Tilup2/CNn+a2htnAAFQyGUNO0R0NDmWlsJGMdbYwlkFU0eWxDk3ruvQAFsk0BfHFLEKduD2N924AvwpTKMG4+0o75tMSEZqDruiocbsGy8iWFUCiMd+6MVLGMK6LhDg2nf9TirfkuqmbuuobzPovg+VA/NsUSVJrPkMp8YXMQP/zxCaCmmwSIuz/do6bPmOXV1JzBfD53jkQxBQAdKtzNWqriYAzX7Y/h/byN1CNS3rUAW7s0ZO8GsPJYB2aTAi3bwYaGqzi3ZIGaXVzqHTr0A+Zy2RABGtQP0+SFpC1U6Z4Ivr036peoG0+MnHYEXmkdQLa+EXee7MRcSiAXEmOxG3j8xCm1bPlq78DBw5jP55sJMDLRYI/UJTuQkqTw08mg5xP1vchWNvgMn/YwlUqqnp5eNb9ssVdTewZHRoZrCHA/AUoBHvXp+Q+C6p0DMRxMiv8AiSGZ+KWtITxw4ZbPPqHpmMlk1Fdf78XZxaWFtrbrePv2yBYmJcwjM5P/Il2aYpsCauP3BChRN0w0DANN00Tg3BeCnunb0NCgqq9vUHNLyse3Vu5A27Yf6HpiCqOtYZg8RCwutPSPsTVXccPBmMo4gNKyMJ1Oo+s6aNsWOo6NyWQSU6mUunjxklq+4nUsnlP2uKkpgNns0MnR0fvMX0GdcWPGYFL8/Wuon8bL2368HW9nU+rS5Svqm2/3IXmso6MTYzfa1cVLl9Xu3XtU+YKlOPOVOWPV1ccxnU7dbQ4GJgvBmT/QlgSamArqV3uPjvGEMZZ0XbWzahcdUouXrFArVr6hXlu2SpXOX0TfxktKFz4+Wn0MHcf5xzSN8mTSJXKTmKYZNMtFUvig620JDy0pMd7fj6tWv1Ugj82ZW+bNKi71ZhfP9xYuWlao3FaFjY0BnxmBpdMp5jhOkZQW8zdtWnImBRTZElhfwpzKAeoA+KOWlmt4+nQNkmkPHz6CtWfOYiTSipZlPchlsyebmwOTiRmBua7r68FsW7CzZ68zWo5UPvWBc2C2LaeNjAxXDefz52gCcrlscHg4X0PWIDVJAMqlMokZgdH2/xceF02SwJ+/igAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "TuringTrader.com, All-Stars Leveraged: A leveraged meta-strategy",
                "title": "TuringTrader.com, All-Stars Leveraged: A leveraged meta-strategy",
                "src": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
                "srcSet": ["/static/2b23f891a5d398e021f35a8c1073fc00/5a46d/logo-512x512.png 300w", "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png 512w"],
                "sizes": "(max-width: 512px) 100vw, 512px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><strong parentName="p">{` Learn More `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/all-stars-portfolios/"
            }}>{`All-Stars Portfolios`}</a></li>
        </ul>
      </Aside>
      <p>{`The operation of `}<em parentName="p">{`Back to Basics`}</em>{` can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`divide capital into three tranches`}</li>
        <li parentName="ul">{`allocate 50% to `}<a parentName="li" {...{
            "href": "/portfolios/li-universal-investment-strategy/"
          }}>{`Universal Investment Strategy`}</a>{`, and 25% each to `}<a parentName="li" {...{
            "href": "/portfolios/keller-defensive-asset-allocation/"
          }}>{`Defensive Asset Allocation`}</a>{`, and `}<a parentName="li" {...{
            "href": "/portfolios/keller-lethargic-asset-allocation/"
          }}>{`Lethargic Asset Allocation`}</a></li>
        <li parentName="ul">{`rebalance between the tranches once per month`}</li>
      </ul>
      <p>{`Please refer to the component strategy's pages for a more detailed description of the methodologies used by each component strategy.`}</p>
      <h2>{`Diversification`}</h2>
      <p><em parentName="p">{`Back to Basics`}</em>{` is broadly diversified across stocks and bonds from various markets and regions, as well as some commodities and hard assets.`}</p>
      <p>{`Also, `}<em parentName="p">{`Back to Basics`}</em>{` diversifies across multiple investment styles. Even though all four component strategies fall into the momentum category, they rely on different momentum flavors. By combining these, `}<em parentName="p">{`Back to Basics`}</em>{` can reduce the portfolio's concentration risk while improving the ability to cope with a broad range of market conditions.`}</p>
      <h3>{`Returns & Volatility`}</h3>
      <p>{`During bull markets, `}<em parentName="p">{`Back to Basics`}</em>{` delivers returns that are about on par with the 60/40 benchmark. However, when contemplating the full economic cycle, the strategy beats its benchmark and the S&P 500 index. The strategy achieves these improvements thanks to its docile behavior during the 2008 and 2020 recession periods.`}</p>
      <p>{`Overall, `}<em parentName="p">{`Back to Basics`}</em>{` delivers decent returns at reduced volatility. The Monte-Carlo simulation confirms these claims of a solid upside and a much-reduced risk over the 60/40 benchmark.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p><em parentName="p">{`Back to Basics`}</em>{` trades frequently and regularly triggers taxable events. However, two of the three tranches, the `}<em parentName="p">{`Universal Investment Strategy`}</em>{` and the `}<em parentName="p">{`Lethargic Asset Allocation`}</em>{`, often hold assets long enough to qualify for long-term treatment of capital gains. While the strategy works best in tax-deferred accounts, it still adds value to taxable accounts.`}</p>
      <p>{`To allow for proper position sizing, `}<em parentName="p">{`Back to Basics`}</em>{` requires a minimum investment of about $25,000.`}</p>
    </Review>
    <Notes mdxType="Notes">
      <p><strong parentName="p">{`Portfolio Revisions`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, October 2020`}</a>{`: Initial release.`}</li>
      </ul>
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      